<template>
    <div class="content" v-loading.fullscreen.lock="loading">
        <base-header class="pb-6">
            <div class="row align-items-center py-2">
                <div class="col-lg-6 col-7"></div>
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div>
                <card class="no-border-card" footer-classes="pt-1">
                    <template slot="header">
                        <div class="row align-items-center">
                            <div class="col-md-6 col-lg-6">
                                <h2 class="mb-0">SCORM Report Details</h2>
                            </div>
                        </div>
                    </template>
                    <div>
                        <div class="user-eltable">
                            <el-table role="table" :data="tableData" stripe highlight-current-row lazy row-key="id" id="tableOne" header-row-class-name="thead-light" class="failPassGrid table-striped">
                                <el-table-column min-width="80px" prop="first_name">
                                    <template slot="header">
                                        <span>Question</span>
                                    </template>
                                    <template slot-scope="props">
                                        <span>{{ props.row.question }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column min-width="80px" prop="last_name">
                                    <template slot="header">
                                        <span>Answer</span>
                                    </template>
                                    <template slot-scope="props">
                                        <span>{{ props.row.answer }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column min-width="80px" prop="last_name">
                                    <template slot="header">
                                        <span>Correct Answer</span>
                                    </template>
                                    <template slot-scope="props">
                                        <span>{{ props.row.result }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>
<script>
import {Table, TableColumn} from "element-ui";
import XLSX from "xlsx";

let timeout = null;
export default {
    name: "ScormReportDetails",
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
    },
    data() {
        return {
            loading: false,
            tableData: [],
        };
    },
    created: function () {
        this.$http.get(`course/scorm-report/details/employee/${this.$route.params.employee_id}/course/${this.$route.params.course_id}`).then((response) => {
            this.tableData = response.data;
        }).finally(() => (
            this.loading = false
        ));
    },
};
</script>
